import { Field, Form, FormElement } from "@progress/kendo-react-form";
import CustomInput from "../components/CustomInput";
import { Button } from "@progress/kendo-react-buttons";
import { validateEmail } from "../utils/validator";
import defaultLogo from "../assets/images/logo.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { AxiosError } from "axios";
import { ForgotPasswordRequest } from "../types/ForgotPasswordRequest";
import accountService from "../services/account.service";
import { useEffect, useState } from "react";
import { Error } from "@progress/kendo-react-labels";
import { Loader } from "@progress/kendo-react-indicators";
import useLocale from "../hooks/useLocale";
import Swal from "sweetalert2";
import { Dictionary } from "../types/Dictionary";
import useBranding from "../hooks/useBranding";
import useTranslation from "../hooks/useTranslation";

const ForgotPassword: React.FC = () => {
  const trans = useTranslation("forgotpassword")
  const localeCtx = useLocale();
  const brandingCtx = useBranding();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const clientId = searchParams.get("clientId");
  const redirectUri = searchParams.get("redirectUri");

  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["forgotpassword"]) {
      trans.fetchTranslations("forgotpassword");
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    if (!clientId) {
      // redirect to unauthorized
      navigate(`/unauthorized`, { replace: true });
    }
  }, []);

  const submitHandler = async (values: { [name: string]: any }) => {
    try {
      setLoading(true);
      if (clientId) {
        const reqPayload: ForgotPasswordRequest = {
          email: values.email,
          language: localeCtx?.selectedLocale?.current.locale.code,
        };
        await accountService.forgotPassword(reqPayload, clientId);
        setSuccess(true);
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        const errCode = err.response?.data?.code;
        if (errCode === "VALIDATION_ERROR") {
          setError(
            trans.fetchLabelKeyTranslation(
                  "OneOrMoreValidation",
                  "One or more validation errors occurred"
                )
          );
          console.log(
            trans.fetchLabelKeyTranslation(
                  "OneOrMoreValidation",
                  "One or more validation errors occurred"
                )
          );
        } else if (errCode === "UNAUTHORIZED") {
          setError(
            trans.fetchLabelKeyTranslation("InvalidCreds", "Invalid Credentials")
          );
        } else if (errCode === "INTERNAL_SERVER_ERROR") {
          setError(
            trans.fetchLabelKeyTranslation(
                  "InternalServerErrorText",
                  "Something went wrong."
                )
          );
        } else {
          const errMessage = err.response?.data?.message;
          setError(errMessage);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const emailValidator = (value: string) => {
    if (!validateEmail(value)) {
      return (
        <Error>
          {trans.fetchLabelKeyTranslation(
                "InvalidEmailText",
                "Invalid email address."
              )}
        </Error>
      );
    }

    return "";
  };

  return (
    <div className="loginFull float-left w-100 h-100">
      <div className="loginBg h-100 p-l-15 p-r-15">
        <div className="row m-b-20 h-100">
          <div className="col-md-12 h-100">
            <div className="formGroup h-100">
              <div
                className="formInrG cardEffect"
                style={{ position: "relative" }}
              >
                {brandingCtx?.branding?.logos.find(
                  (l) => l.name === "HeaderPrimaryLogo"
                )?.logoImageUrl && (
                  <div className="hdrTrk-logo text-center p-t-5 p-b-15">
                    {/* <span className="fs-22 font-weight">LOGO_PLACEHOLDER</span> */}
                    <img
                      src={
                        brandingCtx?.branding?.logos.find(
                          (l) => l.name === "HeaderPrimaryLogo"
                        )?.logoImageUrl
                      }
                      alt="Logo"
                    />
                  </div>
                )}
                {success ? (
                  <span className="tx-green fs-18">
                    {trans.fetchLabelKeyTranslation(
                          "EmailSentText",
                          "An email with a link to reset your password has been sent."
                        )}
                  </span>
                ) : (
                  <Form
                    initialValues={{
                      email: "",
                    }}
                    onSubmit={submitHandler}
                    render={(formRenderProps) => (
                      <FormElement style={{ maxWidth: "100%" }}>
                        <fieldset className={"k-form-fieldset"}>
                          <Error>{error}</Error>
                          <div className="m-b-15">
                            <Field
                              placeholder={`${
                                trans.fetchLabelKeyTranslation(
                                      "EmailFieldPlaceholder",
                                      "Email"
                                    )
                              }`}
                              name="email"
                              value={formRenderProps.valueGetter("email")}
                              component={CustomInput}
                              // validator={emailValidator}
                            />
                            {formRenderProps.modified &&
                              emailValidator(
                                formRenderProps.valueGetter("email")
                              )}
                          </div>
                          <span className="fs-16">
                            {trans.fetchLabelKeyTranslation(
                                  "EmailLinkText",
                                  "A link to reset your password has been sent to this email address."
                                )}
                          </span>
                        </fieldset>
                        <div className="k-form-buttons d-flex justify-content-center m-t-15">
                          <Button
                            type={"submit"}
                            style={{
                              width: "100%",
                              textTransform: "uppercase",
                            }}
                            className={`btn bg-primary text-white fs-16 p-t-7 p-b-7`}
                          >
                            {loading ? (
                              <Loader
                                size="small"
                                type="infinite-spinner"
                                themeColor="light"
                              />
                            ) : (
                              `${
                                trans.fetchLabelKeyTranslation(
                                      "SubmitButton",
                                      "Submit"
                                    )
                              }`
                            )}
                          </Button>
                        </div>
                      </FormElement>
                    )}
                  />
                )}
                <p className="text-center text-muted m-t-6 m-b-0 fs-16">
                  {trans.fetchLabelKeyTranslation("BackToText", "Back To")}{" "}
                  <Link
                    to={`/login?clientId=${clientId}${
                      redirectUri !== null ? `&redirectUri=` + redirectUri : ""
                    }`}
                    className="forgotPasswordLbl btn-link"
                  >
                    {trans.fetchLabelKeyTranslation("LoginText", "Login")}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
