import { AxiosError } from "axios";
import { AppTokenRequest } from "../types/AppTokenRequest";
import { AppTokenResponse } from "../types/AppTokenResponse";
import { ChangePasswordRequest } from "../types/ChangePasswordRequest";
import { ForgotPasswordRequest } from "../types/ForgotPasswordRequest";
import { ResetPasswordRequest } from "../types/ResetPasswordRequest";
import { Response } from "../types/Response";
import { UserLoginRequest } from "../types/UserLoginRequest";
import { customAxios } from "../utils/customAxios";
import { ValidateResetToken } from "../types/ValidateResetToken";
import { PasswordPolicy } from "../types/PasswordPolicy";
import { UserTempTokenRequest } from "../types/UserTempTokenRequest";
import { UserNameTempTokenDto } from "../types/UserNameTempTokenDto";

const accountService = {
  tempToken: async (
    userInfo: UserTempTokenRequest,
    clientId: string
  ): Promise<string> => {
    customAxios.defaults.headers.post["x-tollringauth-clientid"] = clientId;
    const response = await customAxios.post("/account/temp-token", userInfo, {
      headers: { isEncrypted: true },
    });
    const tokenResponse: Response<{ token: string }> = response.data;
    if (tokenResponse.code === "SUCCESS" && tokenResponse.data) {
      return tokenResponse.data?.token;
    } else {
      throw Error(tokenResponse.error);
    }
  },
  getAppToken: async (
    reqBody: AppTokenRequest,
    clientId: string
  ): Promise<AppTokenResponse> => {
    customAxios.defaults.headers.post["x-tollringauth-clientid"] = clientId;
    const response = await customAxios.post("/account/token", reqBody, {
      headers: { isEncrypted: true },
    });
    console.log(response);
    const appTokenResponse: Response<AppTokenResponse> = response.data;
    if (appTokenResponse.code === "SUCCESS" && appTokenResponse.data) {
      return appTokenResponse.data;
    } else {
      throw Error(appTokenResponse.error);
    }
  },
  forgotPassword: async (
    reqBody: ForgotPasswordRequest,
    clientId: string
  ): Promise<string> => {
    customAxios.defaults.headers.post["x-tollringauth-clientid"] = clientId;
    const response = await customAxios.post(
      "/account/forgot-password",
      reqBody
    );
    const resp: Response<string> = response.data;
    if (resp.code === "SUCCESS" && resp.data) {
      return resp.data;
    } else {
      throw Error(resp.error);
    }
  },
  resetPassword: async (
    reqBody: ResetPasswordRequest,
    clientId: string
  ): Promise<string> => {
    customAxios.defaults.headers.post["x-tollringauth-clientid"] = clientId;
    const response = await customAxios.post(
      "/account/reset-password",
      reqBody,
      { headers: { isEncrypted: true } }
    );
    const resp: Response<string> = response.data;
    if (resp.code === "SUCCESS" && resp.data) {
      return resp.data;
    } else {
      throw Error(resp.error);
    }
  },
  changePassword: async (
    reqBody: ChangePasswordRequest,
    clientId: string
  ): Promise<string> => {
    customAxios.defaults.headers.post["x-tollringauth-clientid"] = clientId;
    const response = await customAxios.post(
      "/account/change-password",
      reqBody,
      { headers: { isEncrypted: true } }
    );
    const resp: Response<string> = response.data;
    if (resp.code === "SUCCESS" && resp.data) {
      return resp.data;
    } else {
      throw Error(resp.error);
    }
  },
  logout: async (clientId: string): Promise<boolean> => {
    console.log("Logging out API called");
    customAxios.defaults.headers.post["x-tollringauth-clientid"] = clientId;
    const response = await customAxios.post("/account/logout");
    const resp: Response<boolean> = response.data;
    return resp.data ?? false;
  },
  validateresettoken: async (
    validateToken: ValidateResetToken,
    clientId: string
  ): Promise<boolean> => {
    customAxios.defaults.headers.post["x-tollringauth-clientid"] = clientId;
    const response = await customAxios.post(
      "/account/valresettoken",
      validateToken,
      { headers: { isEncrypted: true } }
    );
    const resp: Response<boolean> = response.data;
    return resp.data ?? false;
  },
  passwordPolicy: async (email: string | null): Promise<PasswordPolicy> => {
      const requestQuery = email == null ? "" : `?userName=${window.btoa(email)}`;
    const response = await customAxios.get(
      `/account/passwordpolicy` + requestQuery
    );
    const resp: Response<PasswordPolicy> = response.data;
    if (resp.code == "SUCCESS" && resp.data) {
      return resp.data;
    } else {
      throw Error(resp.error);
    }
  },
  login: async (
    userLoginDto: UserLoginRequest,
    clientId: string
  ): Promise<string> => {
    customAxios.defaults.headers.post["x-tollringauth-clientid"] = clientId;
    const response = await customAxios.post(`/account/login`, userLoginDto, {
      headers: { isEncrypted: true },
    });
    const tokenResponse: Response<{ token: string }> = response.data;
    if (tokenResponse.code === "SUCCESS" && tokenResponse.data) {
      return tokenResponse.data?.token;
    } else {
      throw Error(tokenResponse.error);
    }
  },
  validateTempToken: async (
    userNameTempToken: UserNameTempTokenDto
  ): Promise<boolean> => {
    const response = await customAxios.post(
      `/account/valtemptoken`,
      userNameTempToken,
      { headers: { isEncrypted: true } }
    );
    const resp: Response<boolean> = response.data;
    if (resp.code === "SUCCESS") {
      return resp.data ?? false;
    } else {
      throw Error(resp.error);
    }
  },
  getUserMFAModes: async (
    userName: string,
    clientId: string
  ): Promise<string[]> => {
    customAxios.defaults.headers.get["x-tollringauth-clientid"] = clientId;
    const response = await customAxios.get(
      `/account/get-user-mfa-modes?userName=${window.btoa(userName)}`
    );
    const resp: Response<string[]> = response.data;
    if (resp.code === "SUCCESS") {
      return resp.data ?? [];
    } else {
      throw Error(resp.error);
    }
  },
  sendMfaEmail: async (
    userNameTempToken: UserNameTempTokenDto,
    clientId: string
  ) => {
    customAxios.defaults.headers.post["x-tollringauth-clientid"] = clientId;
    const response = await customAxios.post(
      `/account/mfa/email-auth`,
      userNameTempToken,
      { headers: { isEncrypted: true } }
    );
    const resp: Response<string[]> = response.data;
    if (resp.code === "SUCCESS") {
      return resp.data ?? [];
    } else {
      throw Error(resp.error);
    }
  },
};

export default accountService;
