import { useEffect, useState } from "react";
import useLocale from "../hooks/useLocale";
import "./UnauthorizedAccess.scss";
import { Dictionary } from "../types/Dictionary";
import Swal from "sweetalert2";
import useTranslation from "../hooks/useTranslation";

const UnauthorizedAccess: React.FC = () => {
  const trans = useTranslation("unauthorizedaccess");
  const localeCtx = useLocale();

  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["unauthorizedaccess"]) {
      trans.fetchTranslations("unauthorizedaccess");
    }
  }, [localeCtx?.selectedLocale]);

  return (
    <div className="TemplateMessageOuter">
      <div className="TemplateMessageInner">
        <div className="TemplateMessageBox">
          <div className="errorContainer float-left w-100 text-center">
            <div className="errorImg">
              <div
                className="notification-msg-i"
                style={{
                  fontSize: "60px",
                  opacity: ".7",
                  padding: "0 0 30px 0",
                }}
              >
                <i className="bi bi-exclamation-triangle text-primary"></i>
              </div>
            </div>
            <div className="errorTxt">
              <div className="errorTxt-h fs-22 font-weight-semi p-b-20">
                {trans.fetchLabelKeyTranslation(
                    "UnauthorizedAccessText",
                    "Unauthorized Access"
                  )
                }
              </div>
              <div className="errorTxt-p fs-16 p-b-20">
                {trans.fetchLabelKeyTranslation(
                    "UnauthorizedAccessMsg",
                    "You are not authorized to view this page"
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnauthorizedAccess;
