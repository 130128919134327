import "./error.scss";
import erro404img from "../assets/images/404-error.gif";
import { Link } from "react-router-dom";
import useLocale from "../hooks/useLocale";
import { useEffect, useState } from "react";
import { Dictionary } from "../types/Dictionary";
import Swal from "sweetalert2";
import useTranslation from "../hooks/useTranslation";

const NotFoundError = () => {
  const trans = useTranslation("notfound")
  const localeCtx = useLocale();

  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["notfound"]) {
      trans.fetchTranslations("notfound");
    }
  }, [localeCtx?.selectedLocale]);

  return (
    <div className="errorContainer float-left w-100 text-center">
      <div className="errorImg">
        <img className="w-30" src={erro404img} alt={"Error! Not Found..."} />
      </div>
      <div className="errorTxt" style={{ position: "relative", top: "-25px" }}>
        <div className="errorTxt-h fs-30 font-weight-semi p-b-20">
          {trans.fetchLabelKeyTranslation(
                "NotFoundPageTitle",
                "Page not found"
              )}
        </div>
        <div className="errorTxt-p fs-16 p-b-20">
          {trans.fetchLabelKeyTranslation(
                "NotFoundPageMsg",
                "Page not found"
              )}
        </div>
      </div>
    </div>
  );
};

export default NotFoundError;
